import { Loader } from '@googlemaps/js-api-loader';
import AddressLocation from '@/model/user/AddressLocation';
import Address from '@/model/address/Address';

let loader: Loader;
let map: any;

async function load() {
  loader = new Loader({
    apiKey: 'AIzaSyDLGojgLTfd420iWkWc8ZSqXgW8cvyCaSc',
    version: 'quarterly',
    language: 'ja'
  });
  await loader.load().catch((error) => {
    throw error;
  });
}
function setMaker(location:AddressLocation): void {
  // eslint-disable-next-line no-new
  new google.maps.Marker({
    map,
    position: location
  });
}
function codeAddress(address: string): Promise<google.maps.GeocoderResponse> {
  const geocoder = new google.maps.Geocoder();
  return geocoder.geocode({ address });
}
export default {

  async getLocation(address:string):Promise<AddressLocation> {
    await load().catch((err) => {
      throw err;
    });
    const result = await codeAddress(address).catch((err) => {
      throw err;
    });
    if (result.results) {
      const l = result.results[0].geometry.location;
      return new AddressLocation(l.lat(), l.lng());
    }
    throw new Error();
  },

  async showMap(canvas: Element, location:AddressLocation):Promise<void> {
    await load();
    const mapOptions = {
      zoom: 14,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
      },
      zoomControl: true,
      center: location,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    if (canvas && canvas instanceof HTMLElement) {
      map = new google.maps.Map(canvas as HTMLElement, mapOptions);
      setMaker(location);
    }
  },

};
