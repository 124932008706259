
import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';
import { State } from 'vuex-class';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import Help from '@/components/Parts/Help.vue';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import AddressApi from '@/module/api/AddressApi';
import UpdateFields from '@/model/user/UpdateFields';

@Component({
  components: {
    Help,
    InputJpDate
  }
})
export default class UserBaseInfoForm extends Vue {
  @Prop({ default: false }) isShinki?:boolean;
  score = 0;
  @Prop() userBaseInfo!: UserBaseInfo;
  @Prop() loadCompleted!:boolean;
  @State('loginUser') loginUser!: UserBaseInfo;
  SearchAddress(zip:string):void{
    AddressApi.Search(zip.replace(/-/g, '')).then((res) => {
      this.userBaseInfo.addressPerson.address = res.data;
    });
  }
  changeItem(item:string):void{
    if (this.loadCompleted) {
      localStorage.setItem(`user${this.userBaseInfo.userId.value}`, JSON.stringify(this.userBaseInfo));
      this.$emit('addUpdateItem', new UpdateFields(item));
    }
  }
  @Watch('userBaseInfo.addressPerson.address.value')
  changeAddress():void {
    this.changeItem('address');
  }
}
